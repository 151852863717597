import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
// import { Link, graphql } from "gatsby"
// import Swiper from "@components/swiper/swiper"
// import Hero from "@components/hero"

import FacultyHero from "../images/facultyHero.jpg"
import essex from "../images/essex.png"
import smith from "../images/smith.png"

// import WebriQForm from "@webriq/gatsby-webriq-form"

const StaffPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Staff" description="" />
    <div
      class="page-headline"
      style={{ backgroundImage: `url(${FacultyHero})` }}
    >
      <div class="container">
        <div class="section-heading text-center">
          <h5 class="font-weight-bold text-uppercase text-orange flair mb-0">
            Staff
          </h5>
          <h1>NARM Training Institute Staff</h1>
        </div>
      </div>
    </div>
    <div class="page-content staff-wrap bg-light">
      <div class="container">
        <div className="row py-5">
          <div className="col-md-4 offset-1 col-sm-12 text-center align-self-center">
            <div className="fac-img">
              <img
                src={essex}
                alt="essex"
                className="img-fluid"
                style={{ width: "400px" }}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 py-3">
            <h3>Tori Essex</h3>
            <p className="position">
              <em>Operations Administrator</em>
            </p>
            <p className="">
              Tori Essex holds the role of Operations Administrator at the NARM
              Training Institute. Tori has more than five years of experience
              with project management in the art and design field, and joins us
              from her previous role as a university admissions counselor. To
              our team, she brings strong creative problem solving skills and an
              eye for detail. <br />
              <br />
              “I’m so excited to be a part of the NARM Training Institute. After
              feeling the life-changing effects of NARM in my own life, I
              remember wishing that I could share the experience with every
              other person in the world. My work for NARM is right in-line with
              that desire. I look forward to supporting the NARM Training
              Institute, and experiencing my curiosity transform into a deeper
              understanding of NARM’s work.”
            </p>
          </div>
        </div>
        <div className="row py-5">
          <div className="col-md-4 offset-1 col-sm-12 text-center align-self-center">
            <div className="fac-img">
              <img
                src={smith}
                alt="smith"
                className="img-fluid"
                style={{ width: "400px" }}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 py-3">
            <h3>Autumn Smith</h3>
            <p className="position">
              <em>Training Coordinator</em>
            </p>
            <p className="">
              Autumn Smith is the Training Coordinator at the NARM Training
              Institute. Autumn joins us with a professional background working
              in a trauma focused treatment center with additional experience in
              client-facing mental health and substance abuse care. With nearly
              10 years of experience in customer service, she truly has a way
              with helping others navigate questions and concerns. Autumn is
              currently pursuing a degree in Social Work with a real passion for
              working with children, specifically in trauma related cases.
              Autumn found this passion at a young age working hands on in
              Haitian orphanages for many years. <br />
              <br /> As the NARM Training Coordinator, Autumn is the main point
              of contact for all NARM Practitioner Training registration,
              application processing, and student questions. She is also here to
              assist with our growing Inner Circle Online Program. <br />
              <br /> “I am incredibly grateful to join this team and community!
              It is thrilling to watch NARM grow on a daily basis and I'm
              honored to be a part of it. I am fortunate to have found a
              workplace with many incredible people and also where my own
              passions align with the goals of the NARM Training Institute.”
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default StaffPage
